$small-screen-breakpoint: 700px;

.faq-page__container.container-fluid {
  margin-top: 10px;

  @media screen and (min-width: $small-screen-breakpoint) {
    margin-top: 80px;
  }
}

.faq-entry {    
  color: #1F2A4F;

  .faqContainer {
    max-width: 1000px;
    float: none;
    margin: 0 auto;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;    
  }

  .questionBox {    
    width: inherit;
    display: flex;
    position: relative;    
    margin-top: 25px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    
    background-color: white;
    font-weight: 700;
    font-size: 45px;
    font-family: DIN-Alternate;
    transform: rotate(1.07deg);
  }

  .answerBox {
    width: 100%;
    position: relative;    
    margin-bottom: 25px;    
    padding: 10px 25px 10px 25px;
    
    background-color: white;    
    font-weight: 700;
    font-size: 24px;
    font-family: DIN-Alternate;
    line-height: 34.92px;
    transform: rotate(0.24deg);
  }
}
