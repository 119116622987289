.simple-metric {
  width: 500px;
  height: 280px;
  display: flex;
  position: relative;
  margin-top: 50px;
}

.simple-metric__container {
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: center;
  position: relative;

  padding-right: 15px;
  padding-left: 15px;
  margin-right: 15px;
  margin-left: 15px;
}

.simple-metric__character {
  z-index: 1;
  position: absolute;
}

.simple-metric__number {
  font-family: 'Rubik', sans-serif;
  width: 100%;
  z-index: 15;
  position: absolute;
  font-size: 120px;
  text-align: center;
  font-weight: 800;
  color: #F68C28;
  top: 0;
  left:0;

  background: url('~/assets/number_mask.png')  repeat center / contain;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  // Looks optional?
  // @media screen and (max-width: 500px) {
  //   width: 100%;
  // }
}

.simple-metric__text {
  position: absolute;
  z-index: 15;
  color: black;
  font-size: 25px;
  text-align: center;
  line-height: 30px;
  bottom: 0;

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
}

.simple-metric__text-row {
  width: 100%;
  background-color: white;
  padding: 0 5px 0 5px;
  margin-top: 5px;
}

// VARIANT 1 //

.metric--owned-businesses-counted {
  width: 400px;
  height: 250px;

  .simple-metric__container {
    max-width: 1000px;
  }

  .simple-metric__character {
    width: 100%;
    height: 300px;
    background: url('~/assets/eunice-de-guzman.png')
      no-repeat center / cover;
    top: 0;
    margin-top: -70px;
  }

  .simple-metric__number {
    margin-top: 70px;
  }

  .simple-metric__text {
    margin-bottom: -30px;
  }

  .simple-metric__decoration--1 {
    width: 240px;
    min-height: 70px;
    background-color: white;
    position: absolute;
    z-index: -1;
    margin-top: 120px;
  }

  .simple-metric__decoration--2 {
    transform: rotate(2deg);
    width: 200px;
    min-height: 40px;
    background-color: #F7E733;
    position: absolute;
    z-index: 5;
    margin-top: 130px;
  }

  .simple-metric__decoration--3 {
    transform: rotate(-2deg);
    width: 200px;
    min-height: 50px;
    background-color: #F7E733;
    position: absolute;
    z-index: 10;
    margin-top: 200px;
  }
}

@media screen and (max-width: 600px) {
  .metric--owned-businesses-counted {
    .simple-metric__character {
      height: 250px;
      margin-top: -45px;
    }

    .simple-metric__number {
      font-size: 100px;
    }

    .simple-metric__text {
      font-size: 20px;
      right: 5%;
      width: 90%;
    }
  }
}

// VARIANT 2 //

.metric--majority-businesses-counted {
  .simple-metric__character {
    width: 40%;
    height: 100px;
    background: url('~/assets/sincerely-media.png')
      no-repeat center / cover;
    left: 0;
    margin-left: 70px;
  }

  .simple-metric__number {
    margin-top: 40px;
  }

  .simple-metric__text {
    right: 15%;
    width: 70%;
    margin-bottom: -5px;
  }

  .simple-metric__decoration--1 {
    width: 50%;
    min-height: 60px;
    background-color: white;
    position: absolute;
    z-index: 2;
    margin-top: 120px;
    left:25%;
  }
  
  .simple-metric__decoration--2 {
    width: 30px;
    height: 70px;
    background-color: #F7E733;
    position: absolute;
    z-index: 5;
    left: 50%;
  }
  
  .simple-metric__decoration--3 {
    width: 100px;
    min-height: 60px;
    background-color: #F7E733;
    position: absolute;
    z-index: 0;
    left: 0;
    margin-top: 80px;
    margin-left: 70px;
  }
  
  .simple-metric__decoration--4 {
    width: 50px;
    min-height: 50px;
    background-color: #F7E733;
    position: absolute;
    z-index: 5;
    bottom: 0;
    right: 0;
    margin-bottom: 70px;
    margin-right: 120px;
  }
}

@media screen and (max-width: 600px) {
  .metric--majority-businesses-counted {
    .simple-metric__character {
      width: 60%;
      margin-left: 20px;
    }

    .simple-metric__number {
      font-size: 100px;
    }

    .simple-metric__text {
      font-size: 20px;
      right: 5%;
      width: 90%;
    }

    .simple-metric__decoration--2 {
      left: 60%;
    }

    .simple-metric__decoration--3 {
      margin-left: 20px;
    }

    .simple-metric__decoration--4 {
      margin-right: 60px;
    }
  }
}

@media screen and (min-width: 1477px) {
  .metric--majority-businesses-counted {
    margin-top: 120px;
  }
}


// VARIANT 3 //

.metric--leaders-counted {
  .simple-metric__character {
    width: 100%;
    height: 220px;
    background: url('~/assets/muhamad-reza-junianto.png')
      no-repeat center / contain;
    margin-right: -140px;
    right: 0;

    @media screen and (max-width: 500px) {
      margin-right: -100px;
    }
  }

  .simple-metric__number {
    margin-top: 60px;
  }

  .simple-metric__text {
    right: 20%;
    width: 60%;
  }

  .simple-metric__decoration--1 {
    width: 50%;
    min-height: 80px;
    background-color: white;
    position: absolute;
    z-index: 2;
    margin-top: 120px;
    /* margin-left: 195px; */
    left:30%;
  }

  .simple-metric__decoration--2 {
    width: 90px;
    height: 80px;
    background-color: #F7E733;
    position: absolute;
    z-index: 5;
    left: 35%;
    margin-top: 80px;
  }

  .simple-metric__decoration--3 {
    width: 70px;
    min-height: 90px;
    background-color: #F7E733;
    position: absolute;
    z-index: 5;
    bottom: 0;
    right: 0;
    margin-bottom: -20px;
    margin-right: 22%;
  }
}

@media screen and (max-width: 500px) {
  .metric--leaders-counted {
    .simple-metric__text {
      right: 10%;
      width: 80%;
    }

    .simple-metric__decoration--1 {
      width: 70%;
      left:15%;
    }

    .simple-metric__decoration--2 {
      left: 20%;
    }

    .simple-metric__decoration--3 {
      margin-right: 12%;
    }
  }
}
  
@media screen and (min-width: 1477px) {
  .metric--leaders-counted {
    margin-top: 100px;
  }
}

// Variant 4, 5, 6

.metric--business-need-1,
.metric--business-need-2,
.metric--business-need-3 {
  margin-left: 20px;
  margin-right: 20px;

  width: 400px;
  height: 250px;

  .simple-metric__container {
    max-width: 1000px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0px;
    margin-right: 0px;
    /* padding, margin 0 */
  }

  .simple-metric__character {
    z-index: -5;
  }

  .simple-metric__text {
    font-size: 20px;
    top: 0;
    left: 0;
    bottom: unset;
    text-transform: uppercase;
  }

  .simple-metric__text-row {
    transform: rotate(-1deg) translateZ(0px);
  }

  .simple-metric__decoration--1 {
    min-height: 70px;
    background-color: white;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
}

// Variant 4

.metric--business-need-1 {
  margin-top: 100px;

  .simple-metric__character {
    width: 230px;
    height: 230px;
    background: url('~/assets/sharon-mccutcheon.png')
      no-repeat center / cover;
    
    top: 0;
    left: 0;
    margin-left: -30px;
    border-radius: 100%;
  }

  .simple-metric__number {
    font-size: 100px;
  }

  .simple-metric__decoration--1 {
    width: 200px;
    // top: unset;
    margin-left: 115px;
    margin-top: 60px;
  }

  .simple-metric__decoration--2 {
    transform: rotate(2deg);
    width: 150px;
    min-height: 50px;
    background-color: #F7E733;
    position: absolute;
    z-index: 0;
    margin-top: 30px;
    margin-left: -20px;
    right: 70px;
  }
  
  .simple-metric__decoration--3 {
    transform: rotate(-2deg);
    width: 50px;
    min-height: 100px;
    background-color: #F7E733;
    position: absolute;
    z-index: -2;
    left: 0;
    margin-left: 100px;
    margin-top: 100px;
  }

  .simple-metric__text {
    margin-left: 80px;
    margin-top: 130px;
  }

  .simple-metric__text-row {
    padding: 0 20px 0 20px;
  }
}

@media screen and (max-width: 600px) {
  .metric--business-need-1 {
    height: 220px;
    margin-top: 0px;

    .simple-metric__decoration--1 {
      margin-left: 50px;
    }

    .simple-metric__decoration--3 {
      margin-left: 30px;
    }

    .simple-metric__character {
      width: 200px;
      height: 200px;
      margin-top: -10px;
    }

    .simple-metric__text {
      width: 100%;
      margin-left: 0px;
    }
  }
}

// Variant 5

.metric--business-need-2 {
  margin-top: 20px;

  .simple-metric__character {
    width: 430px;
    height: 300px;
    background: url('~/assets/allgo-an-app-for-plus-size-people.png')
      no-repeat center / cover;

    top: 0;
    left: 0;
    margin-left: -50px;
    margin-top: -80px;
  }

  .simple-metric__number {
    margin-top: 50px;
  }

  .simple-metric__decoration--1 {
    width: 240px;
    margin-left: 90px;
    margin-top: 90px;
  }
  
  .simple-metric__decoration--2 {
    width: 150px;
    min-height: 60px;
    background-color: #F7E733;
    position: absolute;
    z-index: -4;
    top: 0;
    left: 0;
    margin-left: 70px;
    margin-top: 130px;
  }
  
  .simple-metric__decoration--3 {
    transform: rotate(-2deg);
    width: 60px;
    min-height: 80px;
    background-color: #F7E733;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    margin-right: 40px;
    margin-top: 180px;
  }

  .simple-metric__text {
    margin-left: 50px;
    width: 70%;
    margin-top: 190px;
  }
}

@media screen and (max-width: 600px) {
  .metric--business-need-2 {
    .simple-metric__text {
      right: 5%;
      width: 90%;
    }

    .simple-metric__character {
      width: 350px;
      height: 300px;
      background: url('~/assets/allgo-an-app-for-plus-size-people.png')
        no-repeat center / contain;
    }

    .simple-metric__decoration--1 {
      width: 90%;
      left: 5%;
      margin-left: 0px;
    }

    .simple-metric__decoration--2 {
      margin-left: -10px;
    }

    .simple-metric__decoration--3 {
      margin-right: -10px;
      margin-top: 170px;
    }

    .simple-metric__text {
      width: 100%;
      margin-left: 0px;
      left: 0;
    }
  }
}

// Variant 6

.metric--business-need-3 {
  width: 400px;
  height: 250px;
  margin-top: 100px;

  .simple-metric__character {
    width: 300px;
    height: 300px;
    background: url('~/assets/chungkuk-bae.png')
      no-repeat center / cover;
    z-index: -10;
    top: 0;
    right: 0;
    margin-right: -90px;
  }

  .simple-metric__number {
    font-size: 100px;
    margin-left: -80px;
  }

  .simple-metric__decoration--1 {
    width: 240px;
    margin-top: 60px;
    margin-left: 30px;
  }
  
  .simple-metric__decoration--2 {
    width: 60px;
    min-height: 180px;
    background-color: #F7E733;
    position: absolute;
    z-index: -5;
    top: 0;
    left: 0;
    margin-top: 20px;
  }
  
  .simple-metric__decoration--3 {
    transform: rotate(-2deg);
    width: 60px;
    min-height: 60px;
    background-color: #F7E733;
    position: absolute;
    z-index: 10;
    margin-left: 20px;
    margin-top: 10px;
    right: 120px;
  }
  
  .simple-metric__text {
    margin-left: 10px;
    margin-top: 130px;
    width: 70%;
  }

  .simple-metric__text-row {
    padding: 0 20px 0 20px;
  }
}
 
@media screen and (max-width: 1000px) {
  .metric--business-need-3 {
    .simple-metric__character {
      width: 250px;
      height: 250px;
      margin-right: -95px;
      margin-top: 50px;
    }

    .simple-metric__number {
      margin-left: 0px;
    }
    
    .simple-metric__text {
      font-size: 20px;
      right: 5%;
      /* width: 90%; */
      width: 100%;
      margin-left: 0px;
    }
  }
}

@media screen and (min-width: 700px) {
  .metric--business-need-1 {
    margin-top: 70px;
  }

  .metric--business-need-3 {
    margin-top: 70px;
  }
}

@media screen and (max-width: 500px) {
  .metric--business-need-2 {
    margin-top: 70px;
  }

  .metric--business-need-3 {
    margin-top: 60px;
  }

  .metric--business-need-1,
  .metric--business-need-2,
  .metric--business-need-3 {
    .simple-metric__number {
      width: 100%;
      font-size: 100px;
    }
  
    .simple-metric__text {
      font-size: 18px;
    }
  }
}
