$small-max-breakpoint: 500px;
$medium-max-breakpoint: 850px;
$large-max-breakpoint: 1500px;

.home-page-content .container {
    max-width: 1100px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin-bottom:200px;
    margin-top: 2em;

    @media screen and (min-width: $large-max-breakpoint) {
        margin-left: 300px;
        margin-top: 50px;
    }

    @media screen and (max-width: $small-max-breakpoint) {
        margin-top: 30px;
        padding: 0;
    }
}

.home-page-content .topBackground {
    width:100%;
    height:100%;
    background: url(../../assets/TWU_Home_BG.png) no-repeat;
    background-position: center top;
    background-size: contain;
    z-index: -10;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -135px;

    @media screen and (max-width: $medium-max-breakpoint) {
        margin-top: -100px;
    }
}

.home-page-content .bodyContainer {
    display:flex; flex: 1 1 auto;
    position: relative;
    margin-top: 25px;

    @media screen and (max-width: $medium-max-breakpoint) {
        margin-top: 25px;
    }

    @media screen and (max-width: $small-max-breakpoint) {
        margin-top: 25px;
    }
}

.home-page-content .bodyBox {
    position: relative;
    background-color: white;
    padding: 52px 14% 40px 5%;
    font-weight: 700;
    font-size: 24px;
    font-family: DIN-Alternate;
    line-height: 34.92px;

    p {
        margin-bottom: 1.25em;
    }

    b {
        font-family: DIN-Alternate-Bold;
    }

    @media screen and (max-width: $medium-max-breakpoint) {
        font-size: 18px;
        line-height: 30px;
        padding: 30px 5% 20px 5%;
        max-width: 100%;
    }
}

.home-page-content .highlighted {
    font-size: 45px;
    font-family: DIN-Alternate-Bold;
    line-height: 90px;

    .highlight-text {
        position: relative;
        z-index: 2;
    }

    .highlight-box {
        position: absolute;
        left:100px;
        width:392px;
        height: 24px;
        transform: translateX(-20.5%) translateY(200%) rotate(-1.07deg);
        background: #F7E733;
        z-index: 1;

        @media screen and (max-width: $medium-max-breakpoint) {
            width:280px;
            transform:translateX(-30%) translateY(200%);
        }
    }

    @media screen and (max-width: $small-max-breakpoint) {
        font-size: 35px;
        line-height: 45px;
        
        .highlight-box {
            width:240px;
            transform:translateX(-35%) translateY(100%);
        }
    }
}

.home-page-content .buttonBox {
    line-height: 1.5em;
    padding: 10px 35px 10px 50px;

    position: absolute;
    right: 5%;
    bottom: -0.75em;
    
    font-weight: 700;
    font-size: min(3.3vw, 3.3vh);
    font-family: DIN-Alternate-Bold;
    color: white;
    text-align: right;
    
    background-color: #CD157D;
    transform: rotate(-0.01deg);
}
