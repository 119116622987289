$small-screen-breakpoint: 700px;

.header {
  background-color: white;
  position: relative !important;
  filter: drop-shadow(1px 2px 1px rgba(75, 75, 75, .5));
  height: 120px;
  font-size: 21px;

  @media screen and (max-width: 1200px) {
    font-size: 17px;
  }

  .logo {
    position: absolute;
    background: url(../../assets/TWU_logo2.png) no-repeat left / contain;
    width: 150px;
    height: 200px;
    top: 0;
    left: 0;
    margin-left: 20px;

    &.big {
      width: 350px;
      height: 450px;

      @media screen and (max-width: 1500px) {
        width: 150px;
        height: 200px;
      }
    }
  }

  .nav-link {
    color: #1F2A4F;
  }

  .mobileNav{
    margin-left: auto;
    display: none;
  }

  .dropdown-toggle::after{
    color: transparent;
    background: url(../../assets/hamburger-menu.png) no-repeat center / cover;
    width: 30px;
    height: 30px;
  }

  .dropdown-menu{
    border-radius: 0;
    top: 55px;
    right: -20px;
    width: 250px;
    text-transform: uppercase;
  }

  /* toggle normal navbar and mobile navbar */
  @media screen and (max-width: $small-screen-breakpoint) {
    height: 80px;

    .logo {
      width: 100px;
      height: 100px;

      &.big {
        width: 100px;
        height: 100px;
      }
    }

    .wideNav { display: none; }
    .mobileNav{ display: inline; }
    .nav-link { padding: 0; }
  }
}
