.donut-chart {
  width: 90%;
  max-width: 900px;
  height: 350px;
}

.donut-chart__chart-outer {
  width: 100%;
  display: flex; flex: 0 0 auto;
  flex-direction: row;
}

.donut-chart__heading {
  position: relative;
  margin-bottom: 30px;
}

.donut-chart__heading-decoration--1,
.donut-chart__heading-decoration--2 {
  position: absolute;
  background-color: #F7E733;
  z-index: -10;
  height: 40px;
}

.donut-chart__heading-decoration--1 {
  width: 70px;
  margin-top: 10px;
  margin-left: -20px;
}

.donut-chart__heading-decoration--2 {
  width: 50px;
  margin-top: -10px;
  margin-right: -12px;
  right: 0;
}

.donut-chart__heading-text {
  background: white;
  text-align: center;
  font-size: 23px;
  text-transform: uppercase;
}

.donut-chart__chart-middle-wrapper {
  width: 50%;
  max-width: 1500px;
  position: relative;
}

.donut-chart__character--1 {
  width: 400px;
  height: 400px;
  background: url('~/assets/dede-daps.png')
    no-repeat center / contain;
  z-index: -10;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -160px;
  margin-left: -100px;
}

.donut-chart__chart-decoration--1 {
  position: absolute;
  width: 80%;
  background-color: white;
  z-index: -20;
  height: 170px;
  margin-top: 40px;
  margin-left: 50px;
}

.donut-chart__chart-inner {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -20px;
  margin-top: -100px;
  transform: rotate(40deg);
  z-index: 5;
}

.donut-chart__chart-label {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 5;
  left: 0;
  top: 0;
  margin-left: 39%;
  margin-top: 40%;
  font-weight: 600;
  font-size: 50px;
  color: #F68C28;
  transform: rotate(-40deg);
  font-family: 'Rubik', sans-serif;
}

.donut-chart__chart-circle {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  margin-left: 28%;
  margin-top: 28%;
  background-color: white;
  border-radius: 100%;
  width: 180px;
  height: 180px;
}

.donut-chart__chart-proper {
  width: 400px;
}

.donut-chart__legend {
  width: 50%;
  max-width: 1500px;
  display: flex; flex: 0 0 auto;
  flex-direction: column;
}

.donut-chart__legend-entry {
  display: flex;
  position: relative;
  flex-direction: row;
  margin: 10px 0;
  width: 100%;
}

.donut-chart__legend-color {
  position: absolute;
  margin-top: -5px;
  margin-left: -5px;
  width: 25px;
  height: 25px;
}

.donut-chart__legend-color--1 { background:  #CD157D; }
.donut-chart__legend-color--2 { background: #F68C28; }
.donut-chart__legend-color--3 { background: #F7E733; }
.donut-chart__legend-color--4 { background: #55ACEE; }
.donut-chart__legend-color--5 { background: #4676D3; }

.donut-chart__legend-color--selected {
  border-radius: 5px;
}

.donut-chart__legend-backdrop {
  width: 100%;
  background: white;
}

.donut-chart__legend-text {
  margin: 5px 10px 5px 40px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .donut-chart {
    height: 620px;
  }

  .donut-chart__chart-outer {
    flex-direction: column;
  }

  .donut-chart__chart-middle-wrapper {
    width: 100%;
    height: 250px;
  }

  .donut-chart__legend {
    width: 100%;
  }
}

@media screen and (max-width: 470px) {
  .donut-chart {
    height: 700px;
  }

  .donut-chart__legend-text {
    font-size: 11px;
  }
}
