#filtered-map__outer {
  justify-content: center;
  margin-right: 15px;
  margin-left: 15px;
}

.filtered-map__deco-box--yellow-left {
  width: 50px; /* Was 60px, conflicting with text at some screen sizes */
  min-height: 99px;
  background-color: #F7E733;
  position: absolute;
  z-index: 0;
  top: 0;
  margin-top: 570px;
  left: 0;
  margin-left:-20px;
  transform: rotate(4deg);
}

.filtered-map__deco-box--yellow-right {
  width: 104px;
  min-height: 43px;
  background-color: #F7E733;
  position: absolute;
  z-index: 0;
  top: 0;
  margin-top: 580px;
  right: 0;
  margin-right:-35px;
}

.filtered-map__deco-box--orange-right {
  width: 83px;
  min-height: 53px;
  background-color: #F68C28;
  position: absolute;
  z-index:0;
  top: 0;
  margin-top: -20px;
  right: 0;
  margin-right:-35px;
  transform: rotate(-1deg);
}
