$small-max-breakpoint: 500px;

#filtered-map__outer.report-page__map {
  margin-bottom: 40px;
  padding: 0px 32px;
}

@media screen and (max-width: $small-max-breakpoint) {
  #report-page {
    padding: 0;
  }

  #map-page__row-a,
  #filtered-map__outer.report-page__map {
    margin: 0;
    padding: 0;
  }
}

map-footer-small {
  position: relative;
}

map-footer-small .map-page-link{
  margin:auto;
  align-content: center;
  align-self: center;
  display:flex; flex: 1 1 auto;
  flex-direction: row;
  max-width: 350px;
  background-color: #F68C28;
  margin-top:10px;
  padding: 10px 15px 5px 10px;
  color: white;
  justify-content: center;
  font-size: 24px;
  font-family: DIN-Alternate-Bold;

  &:hover {
    color: white;
  }
}

map-footer-small .map-page-link .arrow-right{
  background-repeat: no-repeat;
  background-position: right;
  background-image: url(../../assets/TWU_Arrow_2_White.png);
  margin-left:10px;
  width: 80px;
  height: 40px;
  background-size: contain;
  z-index: 1;
  pointer-events: none;
}
