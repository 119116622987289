.section-heading {
  width: 90%;
  max-width: 1400px;
}

.section-heading__inner {
  position: relative;
  margin-bottom: 30px;
}

.section-heading__text {
  background: white;
  text-align: center;
  font-size: 23px;
  text-transform: uppercase;
}

.section-heading__decoration--1,
.section-heading__decoration--2 {
  position: absolute;
  background-color: #F7E733;
  z-index: -10;
  height: 40px;
}
.section-heading__decoration--1 {
  width: 70px;
  margin-top: 10px;
  margin-left: -20px;
}
.section-heading__decoration--2 {
  width: 50px;
  margin-top: -10px;
  margin-right: -12px;
  right: 0;
}


/* ================= */


owner-identities-1 {
  width: 90%;
  max-width: 900px;
  height: 400px;
  position: relative;
  margin-top: 40px;
}

owner-identities-1 .container {
  width: 100%;
  display: flex; flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

owner-identities-1 .title-box{
  position: relative;
  margin-bottom: 30px;
}

owner-identities-1 .title-box .title-text{
  background: white;
  text-align: center;
  font-size: 23px;
  text-transform: uppercase;
  /* font-weight: 500; */
}

owner-identities-1 .title-box .yellow-box1{
  position: absolute;
  background-color: #F7E733;
  z-index: -10;
  height: 40px;
  width: 70px;
  margin-top: 10px;
  margin-left: -20px;
}
owner-identities-1 .title-box .yellow-box2{
  position: absolute;
  background-color: #F7E733;
  z-index: -10;
  height: 40px;
  width: 50px;
  margin-top: -10px;
  margin-right: -12px;
  right: 0;
}

owner-identities-1 .character {
  width: 300px;
  height: 300px;
  background: url('~/assets/brooke-cagle.png')
    no-repeat center / contain;
  z-index: -5;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 10px;
}

owner-identities-1 .yellow-box-3{
  position: absolute;
  background-color: #F7E733;
  z-index: -10;
  height: 100px;
  width: 90%;
  margin-top: 50px;
  margin-right: -12px;
  right: 0;
  transform: rotate(1deg);
}
owner-identities-1 .yellow-box-4{
  position: absolute;
  background-color: #F7E733;
  z-index: -4;
  height: 60px;
  width: 50px;
  top: 0;
  margin-top: 250px;
  margin-left: 30px;
  left: 0;
  transform: rotate(2deg);
}

owner-identities-1 .statistics {
  width: 90%;
  margin-left: 25%;
  position: relative;
  justify-content: center;
}

owner-identities-1 .statistics .number-with-label-1,
owner-identities-1 .statistics .number-with-label-2,
owner-identities-1 .statistics .number-with-label-3,
owner-identities-1 .statistics .number-with-label-4,
owner-identities-1 .statistics .number-with-label-5{
  font-family: 'Rubik', sans-serif;
  width: 160px;
  text-align: center;
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  /* position: absolute; */
}
owner-identities-1 .statistics .number-with-label-2 { margin-top: 30px; }
owner-identities-1 .statistics .number-with-label-5 { margin-top: 10px; }
owner-identities-1 .statistics .box-1,
owner-identities-1 .statistics .box-2,
owner-identities-1 .statistics .box-3,
owner-identities-1 .statistics .box-4,
owner-identities-1 .statistics .box-5 {
  font-size: 50px;
  font-weight: 600;
  line-height: 1.1;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
owner-identities-1 .statistics .box-1 { background-color: #CD157D; }
owner-identities-1 .statistics .box-2 { background-color: #F68C28; }
owner-identities-1 .statistics .box-3 { background-color: #FFCC4D; }
owner-identities-1 .statistics .box-4 { background-color: #4676D3; }
owner-identities-1 .statistics .box-5 { background-color: #55ACEE; }

owner-identities-1 .text{
  width: 100%;
  margin-left: 10%;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: -5px;
  font-weight: 500;
}

@media screen and (max-width: 980px) {
  owner-identities-1 { height: 500px; }
  owner-identities-1 .statistics .number-with-label-2 { margin-top: 0px; }
  owner-identities-1 .statistics .number-with-label-5 { margin-top: 0px; }
  owner-identities-1 .statistics {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 550px) {
  owner-identities-1 { height: 750px; }
  owner-identities-1 .title-box .title-text{
    font-size: 20px;
  }
  owner-identities-1 .statistics {
    top: 0;
    margin-top: 90px;
  }
  owner-identities-1 .statistics .number-with-label-1,
  owner-identities-1 .statistics .number-with-label-3,
  owner-identities-1 .statistics .number-with-label-5{
    margin-right: 0px;
    margin-left: 60px;
    margin-bottom: 10px;
  }

  owner-identities-1 .statistics .number-with-label-2,
  owner-identities-1 .statistics .number-with-label-4{
    margin-left: 0px;
    margin-right: 60px;
    margin-bottom: 10px;
  }
}


/* ================= */


section-4 {
  width: 600px;
  height: 400px;
  display: flex;
  position: relative;
}

section-4 .container {
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: center;
  position: relative;
}

section-4 .character {
  width: 100%;
  height: 450px;
  background: url('~/assets/toa-heftiba.png')
    no-repeat center / contain;
  z-index: -1;
  /* margin-right: -120px; */
  position: absolute;
  left: 0;
  margin-left: -100px;
}

section-4 .number {
  font-family: 'Rubik', sans-serif;
  /* width: 100%; */
  /* z-index: 15; */
  /* position: absolute; */
  font-size: 120px;
  text-align: center;
  font-weight: 800;
  margin-top:150px;
  color:  #CD157D;
  top: 0;
  /* right: 0; */
  padding: 0 15px 0 15px;

  background:white;
  line-height: 1.1;
}

section-4 .white-box{
  width: 330px;
  height: 80px;
  background-color: white;
  position: absolute;
  z-index: -5;
  margin-top: 200px;
  margin-left: 170px;
  left:0;
}

section-4 .yellow-box1{
  width: 200px;
  min-height: 70px;
  background-color: #F7E733;
  position: absolute;
  z-index: -2;
  right: 0;
  margin-top: 110px;
  margin-right: 80px;
}

section-4 .yellow-box2{
  width: 70px;
  min-height: 80px;
  background-color: #F7E733;
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 0;
  /* margin-bottom: 0px; */
  margin-left: 150px;
}

section-4 .arrow-right {
  z-index: 20;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 18px solid #F68C28;
  margin-top: 5px;
}

section-4 .arrows{
  position: absolute;
  z-index: 20;
  flex-direction: row;
  display: flex;
  margin-top: 140px;
  right: 0;
  margin-right: 120px;
}
section-4 .arrow-left1, section-4 .arrow-left2 {
  margin: 2px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 18px solid  #CD157D;
}

section-4 .text-labels{
  position: absolute;
  z-index: 15;
  color: black;
  font-size: 25px;
  text-align: center;
  /* font-weight: 500; */
  line-height: 30px;
  top: 0;
  margin-top: 270px;
  right: 0;
}
section-4 .top, section-4 .mid, section-4 .bot{
  background-color: white;
  padding: 0 5px 0 5px;
  max-width: 400px;
}

section-4 .top{ width: 70%; margin-left: -20px; transform: rotate(-1deg);}
section-4 .arrow-with-text{
  display: flex;
  flex-direction: row;
  margin-left: -40px;
  transform: rotate(-2deg);
}
section-4 .mid{  width: auto; margin-left: -40px; font-size: 18px;  padding-left: 50px; padding-right: 10px;}
section-4 .bot { width: 100%; font-size: 15px; /*font-weight: 400;*/}

@media screen and (max-width: 500px) {
  section-4 .number { font-size: 80px; }
  section-4 .text-labels{ font-size: 17px; }
  section-4 .arrow-with-text{ margin-left: 40px; }
  section-4 .arrows{
    margin-top: 130px;
    margin-right: 30px;
  }
  section-4 .top{
    margin-left: 40px;
  }
  section-4 .mid{
    font-size: 14px;
  }
  section-4 .character {
    height: 350px;
    margin-left: -60px;
  }
  section-4 .white-box{
    width: 280px;
    margin-top: 180px;
    margin-left: 30px;
    left:0;
  }
  section-4 .yellow-box1{
    margin-top: 120px;
    margin-right: 0px;
  }
  section-4 .yellow-box2{
    margin-left: 10px;
    margin-bottom: 0px;
  }

}


/* ================= */


section-5 {
  width: 600px;
  height: 400px;
  display: flex;
  position: relative;
}

section-5 .container {
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: center;
  position: relative;
}

section-5 .character {
  width: 100%;
  height: 450px;
  background: url('~/assets/thisisengineering-raeng.png')
    no-repeat center / contain;
  z-index: -1;
  margin-right: -120px;
  position: absolute;
  right: 0;
}

section-5 .number {
  font-family: 'Rubik', sans-serif;
  /* width: 100%; */
  /* z-index: 15; */
  /* position: absolute; */
  font-size: 120px;
  text-align: center;
  font-weight: 800;
  margin-top:155px;
  color: #F68C28;
  top: 0;
  /* left: 0; */
  padding: 0 15px 0 15px;

  background-color:white;
  line-height:1.1;
}

section-5 .white-box{
  width: 250px;
  min-height: 70px;
  background-color: white;
  position: absolute;
  z-index: -5;
  margin-top: 200px;
  margin-left: 150px;
  left:0;
}

section-5 .yellow-box1{
  width: 150px;
  min-height: 50px;
  background-color: #F7E733;
  position: absolute;
  z-index: -2;
  left: 0;
  margin-top: 190px;
  margin-left: 110px;
}

section-5 .yellow-box2{
  width: 70px;
  min-height: 90px;
  background-color: #F7E733;
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 0;
  /* margin-bottom: -30px; */
  margin-left: 160px;
}

section-5 .arrow-right {
  z-index: 20;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 18px solid  #CD157D ;
  margin-top: 5px;
}

section-5 .arrows{
  position: absolute;
  z-index: 20;
  flex-direction: row;
  display: flex;
  margin-top: 140px;
  left: 0;
  margin-left: 170px;
}
section-5 .arrow-left1, section-5 .arrow-left2 {
  margin: 2px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 18px solid #F68C28;
}

section-5 .text-labels{
  position: absolute;
  z-index: 15;
  color: black;
  font-size: 25px;
  text-align: center;
  /* font-weight: 500; */
  line-height: 30px;
  top: 0;
  margin-top: 270px;
  right: 0;
}
section-5 .top, section-5 .mid, section-5 .bot{
  background-color: white;
  padding: 0 5px 0 5px;
}
section-5 .top{ width: 70%; margin-left: -20px; transform: rotate(-1deg);}
section-5 .arrow-with-text{
  display: flex;
  flex-direction: row;
  margin-left: -40px;
  transform: rotate(-2deg);
}
section-5 .mid{  width: auto; margin-left: -40px; font-size: 18px; padding-left: 50px; padding-right: 10px;}
section-5 .bot { width: 100%; font-size: 15px; /*font-weight: 400;*/}

@media screen and (max-width: 500px) {
  section-5 .number { font-size: 90px; }
  section-5 .text-labels{ font-size: 17px; }
  section-5 .arrow-with-text{ margin-left: 0px; }
  section-5 .top{
    margin-left: 40px;
  }
  section-5 .arrows{
    margin-top: 130px;
    margin-left: 90px;
  }
  section-5 .arrow-with-text{
    margin-left: 40px;
  }
  section-5 .mid{ font-size: 14px;}
  section-5 .character {
    height: 350px;
    margin-right: -80px;
  }
  section-5 .text-labels{
    bottom: 0;
  }
  section-5 .white-box{
    margin-top: 180px;
    margin-left: 30px;
  }
  section-5 .yellow-box1{
    margin-top: 160px;
    margin-left: 10px;
  }
  section-5 .yellow-box2{
    margin-bottom: 0px;
    margin-left: 10px;
  }

}


/* ================= */


section-6 {
  width: 600px;
  height: 250px;
  display: flex;
  position: relative;
  margin-top: 50px;
}

section-6 .container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  position: relative;
}

section-6 .character {
  /* width: 100%; */
  width: 300px;
  height: 300px;
  background: url('~/assets/mehmet-turgut-kirkgoz.png')
    no-repeat center / contain;
  z-index: 1;
  position: absolute;
  top: 0;
  margin-top: -70px;
  margin-left: 20px;
  left: 0;
}

section-6 .numberContainer {
  z-index: -1;
  position: absolute;
  top: 0;
  min-width: 280px;
  margin-top: -40px;
  margin-left: -120px;
  display: flex;
  align-content: center;
  justify-content: center;
}

section-6 .number {
  font-family: 'Rubik', sans-serif;
  width: min-content;
  font-size: 90px;
  text-align: center;
  font-weight: 800;
  /* margin-top: 70px; */
  color: white;
  background:  #CD157D;
  padding: 0 15px 0 15px;
  line-height: 1.1;
}

section-6 .yellow-box{
  transform: rotate(2deg);
  width: 80px;
  min-height: 80px;
  background-color: #F7E733;
  position: absolute;
  z-index: 5;
  margin-top: 120px;
  margin-left: -70px;
}

section-6 .orange-box{
  transform: rotate(-2deg);
  width: 230px;
  min-height: 130px;
  background-color: #F68C28;
  position: absolute;
  z-index: -10;
  left: 0;
  margin-left: 50px;
  /* margin-top: 200px; */
}

section-6 .arrows{
  position: absolute;
  z-index: 20;
  flex-direction: row;
  display: flex;
  margin-top: 20px;
  right: 0;
  margin-right: 100px;
}

section-6 .arrow-left1, section-6 .arrow-left2 {
  margin: 2px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 18px solid  #CD157D;
}

section-6 .text-labels{
  width: 70%;
  position: absolute;
  z-index: 15;
  color: black;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  top: 0;
  text-align: left;
  margin-left: -50px;
  margin-top: 50px;
}
section-6 .top, section-6 .bot{
  background-color: white;
  margin-top: 5px;
}
section-6 .top { width: 60%; /*font-weight: 500;*/ padding: 5px 20px 5px 20px; }
section-6 .bot { font-size: 15px; width: 80%; padding: 0 10px 0 10px; margin-left: -40px;}

 @media screen and (max-width: 600px) {
  section-6 .character {
    width: 250px;
    height: 250px;
    left: 0;
    margin-left: -10px;
  }
  section-6 .text-labels {
    width: 100%;
    font-size: 20px;
  }
  section-6 .arrows{
    margin-right: 0px;
  }
  section-6 .numberContainer {

    margin-top: -60px;
    left: 0;
    margin-left: 90px;
    min-width: 250px;
  }
}


/* ================= */


section-7 {
  width: 600px;
  height: 250px;
  display: flex;
  position: relative;
  margin-top: 50px;
}

section-7 .container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  position: relative;
}

section-7 .character {
  width: 380px;
  height: 330px;
  background: url('~/assets/damir-bosnjak.png')
    no-repeat center / contain;
  z-index: 1;
  position: absolute;
  top: 0;
  margin-top: -70px;
  right: 0;
  /* margin-right: -100px; */
}

section-7 .number {
  font-family: 'Rubik', sans-serif;
  z-index: 1;
  position: absolute;
  font-size: 90px;
  text-align: center;
  font-weight: 800;
  color: white;
  background:  #CD157D;
  top: 0;
  padding: 0 15px 0 15px;
  margin-top: 55px;
  /* left: 0; */
  margin-left: -170px;
  line-height: 1.1;
}

section-7 .yellow-box{
  transform: rotate(2deg);
  width: 460px;
  min-height: 80px;
  background-color: #F7E733;
  position: absolute;
  z-index: -15;
  margin-top: 90px;

  margin-left: -35%;
}

section-7 .orange-box{
  transform: rotate(-2deg);
  background-color: #F68C28;
  position: absolute;
  z-index: 10;
  /* left: 0; */
  font-size: 60px;
  text-align: center;
  /* font-weight: 800; */
  color: white;
  padding: 0 30px 0 30px;
  line-height: 1.1;
  margin-top: 150px;
  margin-left: -200px;
}

section-7 .arrows{
  position: absolute;
  z-index: 20;
  flex-direction: column;
  display: flex;
  right: 0;
  margin-right: 120px;
}

section-7 .arrow-up1, section-7 .arrow-up2 {
  margin: 2px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 18px solid  #CD157D;
}

section-7 .top-text{
  /* width: 90%; */
  width: 290px;
  position: absolute;
  z-index: 2;
  /* color: black; */
  background: white;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  top: 0;
  left: 0;
  text-align: left;
  padding: 5px 30px 5px 25px;
  margin-top: -10px;
  margin-left: 100px;
  /* font-weight: 500; */
}

 @media screen and (max-width: 600px) {
  section-7 .character {
    width: 320px;
    height: 300px;
    background: url('~/assets/damir-bosnjak.png')
      no-repeat center / contain;
    z-index: 1;
    position: absolute;
    top: 0;
    margin-top: -70px;
    right: 0;
    margin-right: -50px;
  }
  section-7 .yellow-box{
    width: 300px;
    right: 0;
  }
  section-7 .orange-box{
    left: 0;
    margin-left: 0px;
  }
  section-7 .number {
    margin-left: -150px;
    z-index: 1;
  }
  section-7 .arrows{
    margin-right: 40px;
  }
  section-7 .top-text{
    width: 290px;
    margin-left: 0px;
  }
  section-7, section-6 {
    height: 250px;
  }
  section-6 .text-labels, section-7 .top-text {
    font-size: 15px;
  }
}


/* ================= */


section-8 {
  width: 600px;
  height: 400px;
  display: flex;
  position: relative;
}

section-8 .container {
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: center;
  position: relative;
}

section-8 .character {
  width: 100%;
  height: 450px;
  background: url('~/assets/gabriel-jimenez.png')
    no-repeat center / contain;
  z-index: 1;
  position: absolute;
  right: 0;
  margin-right: -150px;
  top: 0;
  margin-top: -80px;
}

section-8 .number {
  font-family: 'Rubik', sans-serif;
  z-index: 15;
  position: absolute;
  font-size: 120px;
  text-align: center;
  font-weight: 800;
  margin-top:130px;
  color:  #CD157D;
  top: 0;
  left: 0;

  background: url('~/assets/number_mask.png')  repeat center / contain;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

section-8 .white-box{
  width: 300px;
  height: 80px;
  background-color: white;
  position: absolute;
  z-index: 2;
  margin-top: 200px;
  margin-left: 20px;
  left: 0;
}

section-8 .yellow-box1{
  width: 200px;
  min-height: 70px;
  background-color: #F7E733;
  position: absolute;
  z-index: 5;
  margin-top: 160px;
  left: 0;
  margin-left: 160px;
}

section-8 .yellow-box2{
  width: 110px;
  min-height: 40px;
  background-color: #F7E733;
  position: absolute;
  z-index: 5;
  bottom: 0;
  right: 0;
  margin-bottom: 100px;
  margin-right: 155px;
}

section-8 .arrow-right {
  z-index: 20;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 18px solid #F68C28;
  margin-top: 5px;
}

section-8 .arrows{
  position: absolute;
  z-index: 20;
  flex-direction: row;
  display: flex;
  top: 0;
  margin-top: 230px;
  right: 0;
  margin-right: 180px;
}
section-8 .arrow-left1, section-8 .arrow-left2 {
  margin: 2px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 18px solid  #CD157D;
}

section-8 .text-labels{
  position: absolute;
  z-index: 15;
  color: black;
  font-size: 25px;
  text-align: center;
  /* font-weight: 500; */
  line-height: 30px;
  bottom: 0;
  margin-bottom: -10px;
  left: 0;
  margin-left: 100px;
}
section-8 .top, section-8 .mid, section-8 .bot{
  background-color: white;
  padding: 0 5px 0 5px;
}

section-8 .top{ width: 90%; margin-left: -20px; transform: rotate(-1deg); margin-bottom: 20px;}
section-8 .arrow-with-text{
  display: flex;
  flex-direction: row;
  margin-left: -40px;
  transform: rotate(-2deg);
}
section-8 .mid{  width: auto; margin-left: -40px; font-size: 18px;  padding-left: 50px; padding-right: 10px;}
section-8 .bot { width: 100%; font-size: 15px; /*font-weight: 400;*/}

@media screen and (max-width: 500px) {
  section-8 .character {
    height: 350px;
    margin-right: -130px;
    margin-top: -50px;
  }
  section-8 .number { font-size: 90px; }
  section-8 .text-labels{ width: 100%; margin-bottom: 10px; margin-left: 20px; font-size: 17px; }
  section-8 .arrow-with-text{ margin-left: 0px; }
  section-8 .top{ width: 100%; margin-bottom: 5px; }
  section-8 .mid{ font-size: 14px; }
  section-8 .bot { width: 90%; }
  section-8 .yellow-box1{
    z-index: -25;
    margin-left: 130px;
  }
  section-8 .arrows{
    margin-top: 190px;
    margin-right: 20px;
  }

}


/* ================= */


section-9 {
  width: 600px;
  height: 400px;
  display: flex;
  position: relative;
}

section-9 .container {
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: center;
  position: relative;
}

section-9 .character {
  width: 100%;
  height: 500px;
  background: url('~/assets/muhamad-reza-junianto_mirrored.png')
    no-repeat center / contain;
  z-index: 1;
  position: absolute;
  left: 0;
  margin-left: -170px;
  top: 0;
  margin-top: -100px;
  /* transform: scale(-1, 1); */
}

section-9 .number {
  font-family: 'Rubik', sans-serif;
  z-index: 15;
  position: absolute;
  font-size: 120px;
  text-align: center;
  font-weight: 800;
  margin-top:130px;
  color:  #CD157D;
  margin-left: 30%;
  top: 0;
  left: 0;

  background: url('~/assets/number_mask.png')  repeat center / contain;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

section-9 .white-box{
  width: 280px;
  height: 80px;
  background-color: white;
  position: absolute;
  z-index: 2;
  margin-top: 200px;
  margin-left: 170px;
  left:0;
}

section-9 .yellow-box1{
  width: 70px;
  min-height: 70px;
  background-color: #F7E733;
  position: absolute;
  z-index: 5;
  left: 0;
  margin-top: 160px;
  margin-left: 150px;
}

section-9 .yellow-box2{
  width: 150px;
  min-height: 30px;
  background-color: #F7E733;
  position: absolute;
  z-index: 5;
  top: 0;
  margin-top: 255px;
  left: 0;
  margin-left: 250px;
}

section-9 .arrow-right {
  z-index: 20;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 18px solid #F68C28;
  margin-top: 5px;
}

section-9 .arrows{
  position: absolute;
  z-index: 20;
  flex-direction: column;
  display: flex;
  top: 0;
  margin-top: 100px;
  margin-left: -100px;
}
section-9 .arrow-left1, section-9 .arrow-left2 {
  margin: 2px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 18px solid #F68C28;
}

section-9 .text-labels{
  width: 100%;
  position: absolute;
  z-index: 15;
  color: black;
  font-size: 25px;
  text-align: center;
  /* font-weight: 500; */
  line-height: 30px;
  bottom: 0;
  margin-bottom: -10px;
  margin-left: -120px;
}
section-9 .top, section-9 .mid {
  background-color: white;
  padding: 0 5px 0 5px;
}

section-9 .top{ width: 60%; margin-left: -20px; margin-bottom: 10px; transform: rotate(-1deg);}
section-9 .arrow-with-text{
  display: flex;
  flex-direction: row;
  margin-left: -40px;
  transform: rotate(-2deg);
}
section-9 .mid{  width: 60%; text-align:left; margin-left: -40px; font-size: 18px;  padding-left: 50px; padding-right: 10px;}

@media screen and (max-width: 500px) {
  section-9 .character {
    width: 100%;
    height: 400px;
    margin-left: -90px;
    margin-top: -50px;
  }
  section-9 .white-box{
    width: 240px;
    height: 80px;
    background-color: white;
    position: absolute;
    z-index: 2;
    margin-top: 180px;
    margin-left: 90px;
    left:0;
  }
  section-9 .yellow-box1{
    width: 60px;
    min-height: 60px;
    left: 0;
    margin-top: 160px;
    margin-left: 70px;
  }
  section-9 .yellow-box2{
    width: 110px;
    top: 0;
    margin-top: 210px;
    left: 0;
    margin-left: 230px;
  }
  section-9 .number { font-size: 90px; }
  section-9 .text-labels{
    width: 100%;
    margin-bottom: 30px;
    left: 0;
    margin-left: 30px;
    font-size: 17px;
  }
  section-9 .arrows {
    margin-left: -40px;
  }
  section-9 .arrow-with-text{ margin-left: 0px; }
  section-9 .top{ width: 100%; }
  section-9 .mid{ width: 100%; font-size: 14px; }
}


/* ================= */


section-10 {
  /* width: 600px; */
  width: 1000px;
  height: 300px;
  display: flex;
  margin-top: 50px;
}

section-10 .container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

section-10 .character {
  width: 550px;
  height: 500px;
  background: url('~/assets/shingi-rice.png')
    no-repeat center / contain;
  z-index: -10;
  margin-right: -60px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -150px;
}

section-10 .number {
  font-family: 'Rubik', sans-serif;
  z-index: 15;
  position: absolute;
  font-size: 180px;
  text-align: center;
  font-weight: 800;
  /* margin-top:130px; */
  color: #F68C28;
  top: 0;
  left: 0;
  overflow: visible;

  background: url('~/assets/number_mask.png') repeat center / contain;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

section-10 .white-box{
  width: 350px;
  min-height: 100px;
  background-color: white;
  position: absolute;
  z-index: -10;
  left: 0;
  top: 0;
  margin-top: 80px;
}

section-10 .yellow-box1{
  width: 50px;
  min-height: 50px;
  background-color: #F7E733;
  position: absolute;
  z-index: -15;
  left: 0;
  margin-top: 210px;
  margin-left: -10px;
}

section-10 .yellow-box2{
  width: 60px;
  min-height: 40px;
  background-color: #F7E733;
  position: absolute;
  z-index: 5;
  bottom: 0;
  right: 0;
  margin-bottom: -10px;
  margin-right: -100px;
}

section-10 .arrowsup{
  position: absolute;
  z-index: 20;
  flex-direction: column;
  display: flex;
  margin-top: 50px;
  top: 0;
  margin-left: 80px;
}
section-10 .arrow-up1, section-10 .arrow-up2 {
  margin: 2px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 18px solid #F68C28;
}

section-10 .arrowsleft{
  position: absolute;
  z-index: 20;
  flex-direction: row;
  display: flex;
  margin-top: 50px;
  right: 0;
  margin-right: 130px;
}
section-10 .arrow-left1, section-10 .arrow-left2 {
  margin: 2px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 12px solid  #CD157D;
}

section-10 .left, section-10 .right{
  position: absolute;
  background-color: white;
  padding: 2px 20px 2px 20px;
}

section-10 .left{
  font-size: 25px;
  /* font-weight: 500; */
  text-align: center;
  width: 45%;
  /* margin-left: -20px;  */
  left: 0;
  transform: rotate(-1deg);
  top: 0;
  margin-top: 230px;
}

section-10 .right {
  width: 60%;
  right: 0;
  font-size: 20px;
  /* font-weight: 400; */
  top: 0;
  margin-top: 220px;
  padding: 5px 20px 10px 20px;
  margin-right: -70px;
}

@media screen and (max-width: 600px) {
  section-10 .number { font-size: 110px; }
  section-10 .text-labels{ font-size: 17px; }
  section-10 .character {
    width: 330px;
    height: 300px;
    top: 0;
    margin-top: -100px;
    margin-right: 0px;
  }
  section-10 .number {
    left: 0;
    margin-left: 0%;
  }
  section-10 .left {
    font-size: 18px;
    width: 80%;
    margin-top: 150px;
  }
  section-10 .right {
    font-size: 17px;
    width: 90%;
    margin-top: 210px;
    margin-right: 0px;
  }
  section-10 .arrowsup{
    margin-left: 130px;
  }
  section-10 .arrowsleft{
    margin-right: 10px;
  }
}
