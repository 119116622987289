/**
// Appears to be unnecessary
.details-popover__outer {
  color: black;
  position: absolute;
  z-index: 1;
}
*/

.details-popover__rect {
  position: absolute;
  padding-top: 10px; 
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 20px;
  margin-top: -20px;
  margin-left: 25px;
  min-width: 248px;

  background-color: white;
  font-family: DIN-Alternate;
  font-weight: 700;
  font-size: 21.1px;
  line-height: 24.56px;
  box-shadow: 0px 0px 6.5942px rgba(0, 0, 0, 0.25);
  
  a {
    font-family: DIN-Alternate;
    font-weight: 700;
    font-size: 15.83px;
    line-height: 18.42px;
    text-decoration-line: underline;
  }
}

.details-popover__point {    
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 12px solid white;
  left: -12px;
  top: 6px;
  margin-top: -20px;
  margin-left: 25px;
}
