.stats-cloud {
  width: 500px;
  max-width: 900px;
  height: 440px;
  margin: 0 50px 0 50px;
}

.stats-cloud__container {
  width: 100%;
  display: flex; flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.stats-cloud__title {
  position: relative;
  background-color: white;
  font-size: 30px;
  line-height: 1.1;
  margin-bottom: 30px;
  text-align: center;
}

.stats-cloud__cloud-wrapper {
  position: relative;
  width: 100%;
}

.stats-cloud__backdrop {
  width: 320px;
  min-height: 200px;
  background-color: white;
  position: absolute;
  z-index: -5;
}

.stats-cloud__item {
  color: white;
  font-weight: 500;
  text-align: center;
  position: absolute;
  font-family: 'Rubik', sans-serif;
}

.stats-cloud__item--1 {
  font-size: 55px; line-height: 160px; height: 160px; width: 160px; background-color:  #CD157D;
  left: 50%;
  margin-left: -130px;
}

.stats-cloud__item--2 {
  font-size: 45px; line-height: 120px; height: 120px; width: 120px; background-color: #F68C28;
  left: 50%;
  margin-left: 40px;
  margin-top: 100px;
}

.stats-cloud__item--3 {
  font-size: 35px; line-height: 90px; height: 90px; width: 90px; background-color: #41C0E8;
  left: 50%;
  margin-left: 40px;
  margin-top: -5px;
}

.stats-cloud__item--4 {
  font-size: 25px; line-height: 60px; height: 60px; width: 60px; background-color: #304FBC;
  left: 50%;
  margin-left: 140px;
  margin-top: 25px;
}

.stats-cloud__item--5 {
  font-size: 20px; line-height: 50px; height: 50px; width: 50px; background-color: #F2CB00;
  left: 50%;
  margin-left: 170px;
  margin-top: 100px;
}

.stats-cloud__detail {
  display: flex;
  position: absolute;
  width: 80%;
  top: 0;
  margin-top: 320px;
}

.stats-cloud__detail-box {
  background: white;
  width: 100%;
}

.stats-cloud__detail-bar {
  background-color: 15px solid  #CD157D;
  width: 30px;
  height: 50px;
  text-align: center;
  color: white;
  font-size: 25px;
  padding: 5px;
}

.stats-cloud__detail-arrow {
  position: absolute;
  z-index: 20;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid  #CD157D;
  margin-top: 10px;
  margin-left: 18px;
}

.stats-cloud__detail-text {
  position: relative;
  margin: 5px 10px 5px 40px;
  min-height: 40px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

.stats-cloud__detail-quotemark {
  position: absolute;
  font-size: 40px;
  margin-left: 20px;
  margin-top: -10px;
  color:  #CD157D;
}

.stats-cloud__detail-quotemark--closing {
  transform: rotate(180deg);
  right: 0;
  margin-top: -40px;
}

@media screen and (max-width: 500px) {
  .stats-cloud__title {
    width: 300px;
    font-size: 20px;
  }

  .stats-cloud__cloud-wrapper {
    position: relative;
    width: 100%;
    left: 0;
    margin-left: 50px;
  }

  .stats-cloud__detail {
    margin-top: 340px;
    left: 0;
    width: 100%;
  }

  .stats-cloud__detail-box {
    width: 93%;
  }
}

// Variants

.metric--startup-funds {
  .stats-cloud__character {
    width: 270px;
    height: 270px;
    background: url('~/assets/sharon-mccutcheon.png')
      no-repeat center / contain;
    z-index: -10;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 70px;
    margin-left: -40px;
  }

  .stats-cloud__backdrop {
    margin-left: -100px;
    left: 50%;
  }

  @media screen and (max-width: 500px) {
    height: 470px;

    .stats-cloud__cloud-wrapper {
      margin-right: 45%;
    }

    .stats-cloud__backdrop {
      background: white;
    }
  }
}

.metric--impetus {
  .stats-cloud__character {
    width: 450px;
    height: 450px;
    background: url('~/assets/engin-akyurt.png')
      no-repeat center / contain;
    z-index: -5;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: -80px;
    margin-top: -10px;
  }

  .stats-cloud__backdrop {
    z-index: -10;
    margin-left: 30px;
  }

  .stats-cloud__item--1 {
    margin-left: -250px;
  }

  .stats-cloud__item--2 {
    margin-left: -80px;
    margin-top: -15px;
  }

  .stats-cloud__item--3 {
    margin-left: -80px;
    margin-top: 120px;
  }

  .stats-cloud__item--4 {
    margin-left: 20px;
    margin-top: 140px;
  }

  .stats-cloud__item--5 {
    margin-left: 50px;
    margin-top: 80px;
  }

  @media screen and (max-width: 500px) {
    height: 430px;

    .stats-cloud__cloud-wrapper {
      margin-left: 52%;
    }

    .stats-cloud__backdrop {
      left: 0;
      margin-left: -90px;
    }

    .stats-cloud__detail {
      margin-top: 300px;
      left: 0;
      width: 100%;
    }
  }
}
