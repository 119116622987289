.footer {
  display: flex;
  flex-direction: row;
  padding: 10px;
  background: white;
  filter: drop-shadow(1px -1px 1px rgba(75, 75, 75, .5));
  width: 100%;

  .logoSmall{
    background: url('../../assets/VWF_Logo_HorizFullColor-RGB__ScaleWidthWzc5NV0 1.png') no-repeat top / contain;
    width: 140px;
    height: 100px;
  }

  .footerRight,
  .footerMid,
  .footerLeft{
    width: 33%;
    padding: 10px;
  }

  .footerRight{
    line-height: 1;
  }

  .footerMid{
    line-height: 1.6;
  }

  .footerLeft{
    color: #9C9C9C;
    font-size: 10px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    .footerRight,
    .footerMid,
    .footerLeft{
      width: 100%;
    }
  }
}
