/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
@font-face {
  font-family: DIN-Alternate;
  src: url(./assets/fonts/Barlow-Regular.ttf);
}
@font-face {
  font-family: DIN-Alternate-Medium;
  src: url(./assets/fonts/Barlow-Medium.ttf);
}
@font-face {
  font-family: DIN-Alternate-Bold;
  src: url(./assets/fonts/Barlow-Bold.ttf);
}

*{
  margin:0;
  padding:0;
}

#root{
  height:100vh;
  overflow: visible;
  font-family: 'DIN-Alternate', sans-serif;
  font-weight: 600;
}

div{
  color: #1F2A4F;
}

.orangeLink {
  color: #F68C28;
  text-decoration: underline;
}

#main{
  height: auto;
  overflow-x: hidden;

  /* dotted pattern */
  /* Colors */
  --bg-color: #f2f2f2;
  --dot-color: #d9d9d9;

  /* Dimensions */
  --dot-size: 3px;
  --dot-space: 15px;

  background:
      linear-gradient(90deg, var(--bg-color) calc(var(--dot-space) - var(--dot-size)), transparent 1%),
      linear-gradient(var(--bg-color) calc(var(--dot-space) - var(--dot-size)), transparent 1%),
      var(--dot-color);
	background-size: var(--dot-space)  var(--dot-space);
}

body {
  height:100vh;
}

.VictoryContainer {
  /* this fixes a bug with mobile scrolling */
  touch-action: auto !important;
}
