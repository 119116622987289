$small-max-breakpoint: 500px;

#map-page__row-a {
  justify-content: center;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 0px;

  .map-page__filters {
    padding-right: 0;
    padding-left: 0;
  }
}

#filtered-map__outer.map-page__map {
  margin-bottom: 115px;
}

@media screen and (max-width: $small-max-breakpoint) {
  #map-page {
    padding: 0;
  }

  #map-page__row-a,
  #filtered-map__outer.map-page__map {
    margin-left: 0;
    margin-right: 0;
  }
}

.map-page__filters {
  .map-page__filter-row {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: white;
  }

  .map-page__filter-row:first-child {
    padding: 25px 35px 15px;
    z-index: 2;

    @media screen and (max-width: 720px) {
      flex-direction: column;
      padding-right: 80px;
    }

    @media screen and (max-width: $small-max-breakpoint) {
      padding: 15px 30px;
    }
  }
  
  .map-page__filter-row:last-child {
    padding: 0px 35px 35px;
    z-index: 1;
  }
  
  .map-page__search-input {
    font-family: DIN-Alternate-Medium;
    background-color: #F0F0F0;
    color: #1F2A4F;
    font-size: 20px;
    padding: 5px 10px 5px 10px;
    width: 100%;
    border: 0;
    
    &::placeholder,
    &::-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: #A8A8A8;
    }
  }
  
  .filter-label {
    color: #1F2A4F;
    font-size: 22px;
    font-family: DIN-Alternate-Medium;
    width: 100%;
    margin-left: 25px;
    margin-right: 25px;

    @media screen and (max-width: $small-max-breakpoint) {
      margin: 0;
    }
  }
  
  .filter-select {
    padding: 0px;
    width: 100%;
  }
  
  .dropdown-toggle {
    /* NOTE - If you change the sizes here, change the transform style override on hanging-menu in MapFilter.js */
    background-color: #F0F0F0;
    width: 100%;
    padding: 5px 10px 5px 10px;
    text-align: left;
    font-family: DIN-Alternate-Medium;
    font-size: 20px;
    border: 0;
  }
  
  .dropdown-menu {
    max-height: 400px;
    overflow-y: scroll;
    width: 100%;
    font-family: DIN-Alternate-Medium;
    color: #1F2A4F;
    z-index: 1;
  }
  
  .dropdown-select-button {
    width: 100%;
    text-align: left;
    font-family: DIN-Alternate-Medium;
    font-size: 27px;
    border: 0;
  }
  
  @media screen and (max-width: 580px) {
    .filter-label { font-size: 20px; }
    .map-page__search-input { font-size: 15px; }
    .dropdown-toggle { font-size: 15px; margin-bottom: 10px; }
    /* NOTE - If you change the sizes in this media query, change the transform style override on hanging-menu in MapFilter.js */
  }
}

.map-page__footer {
  flex: 1 1 auto;
  background-color: white;
  padding: 30px 35px 40px;
  font-weight: 700;
  font-size: 25px;
  font-family: DIN-Alternate;
  text-align: center;
  line-height: 34.92px;
  width:100%;

  @media screen and (max-width: 850px) {
    font-size: 18px;
    line-height: 28px;
  }
}

