.top-values {
  width: 90%;
  max-width: 900px;
  height: 200px;
}

.top-values__body {
  width: 100%;
  display: flex; flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.top-values__title {
  position: relative;
  margin-bottom: 30px;
}

.top-values__title-text {
  background: white;
  text-align: center;
  font-size: 23px;
  text-transform: uppercase;
}

.top-values__title-decoration--1,
.top-values__title-decoration--2 {
  position: absolute;
  background-color: #F7E733;
  z-index: -10;
  height: 40px;
  width: 70px;
  margin-top: 10px;
  margin-left: -20px;
}

.top-values__title-decoration--2 {
  width: 50px;
  margin-top: -10px;
  margin-right: -12px;
  right: 0;
}

.top-values__character--1 {
  width: 300px;
  height: 300px;
  z-index: -20;
  position: absolute;
  top: 0;
}

.top-values__character--2 {
  z-index: -20;
  position: absolute;
  top: 0;
  left: 0;
}

.top-values__value-column {
  width: 50%;
  max-width: 1500px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.top-values__value-text-outer {
  width: 100%;
  margin: 0 0 0 5px;
  background: white;
}

.top-values__quotemark--opening,
.top-values__quotemark--closing {
  position: absolute;
  font-size: 40px;
  margin-left: 20px;
  margin-top: -10px;

}

.top-values__quotemark--closing {
  transform: rotate(180deg);
  right: 0;
  margin-top: -40px;
}

.top-values__value-text-inner {
  margin: 5px 10px 5px 40px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

.top-values__value {
  display: flex;
  position: relative;
  flex-direction: row;
  margin: 5px 0;
}

.top-values__value-box {
  font-family: 'Rubik', sans-serif;
  width: 70px;
  height: 50px;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 25px;
  padding: 5px; // or padding-top?   
}

.top-values__value-arrow {
  position: absolute;
  z-index: 20;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  margin-top: 10px;
  margin-left: 52px;
}

.top-values__value--1 {
  .top-values__quotemark {
    color: #CD157D;
  }

  .top-values__value-box {
    background: #CD157D;
  }
  
  .top-values__value-arrow {
    border-left: 15px solid #CD157D;
  }
}

.top-values__value--2 {
  .top-values__quotemark {
    color: #F68C28;
  }

  .top-values__value-box {
    background: #F68C28;
  }
  
  .top-values__value-arrow {
    border-left: 15px solid #F68C28;
  }
}

.top-values__value--3 {
  .top-values__quotemark {
    color: #F7E733;
  }

  .top-values__value-box {
    background: #F7E733;
  }
  
  .top-values__value-arrow {
    border-left: 15px solid #F7E733;
  }
}

.top-values__quote {
  padding: 0 0 0 50px;
  position: relative;
}

.top-values__quote-backdrop {
  background-color: white;
  padding: 20px;
  transform: rotate(-1deg);
  font-size: 18px;
  line-height: 22px;
}

.top-values__quote-decoration--1 {
  background-color: #F7E733;
  position: absolute;
  z-index: -10;
  width: 100px;
  height: 40px;
  margin-left: -15px;
  margin-top: -15px;
}

.top-values__quote-decoration--2 {
  background-color:  #CD157D;
  position: absolute;
  right: 0;
  z-index: -10;
  height: 100px;
  width: 40px;
  margin-top: -70px;
  margin-right: -15px;
  transform: rotate(10deg);
}

.top-values__quote-attribution {
  position: absolute;
  margin-top: -10px;
  right: 0;
  margin-right: 5px;
  transform: rotate(-2deg);
  background-color: #F68C28;
  color: white;
  padding: 2px 12px;
}

@media screen and (max-width: 600px) {
  .top-values__body { flex-direction: column; }
  .top-values__value-column { width: 100%; }
}

// VARIANTS

// Formerly chart-2
.metric--defining-success {
  .top-values__character--1 {
    right: 0;
    background: url('~/assets/jesse-orrico.png')
      no-repeat center / contain;
    margin-top: -160px;
    margin-right: -50px;
  }

  .top-values__character--2 {
    width: 300px;
    height: 300px;
    background: url('~/assets/jesse-orrico.png')
      no-repeat center / contain; 
    margin-left: 150px;
    transform: rotate(250deg) scale(1, -1);
  }

  @media screen and (max-width: 500px) {
    height: 430px;
  }

  @media screen and (max-width: 700px) {
    height: 350px;

    .top-values__quote {
      left: 0;
      width: 120%;
      margin-top: 30px;
      margin-left: -50px;
    }

    .top-values__character--1 {
      top: 0;
      right: 0;
      margin-top: -200px;
      margin-right: 50px;
    }

    .top-values__character--2 {
      top: 0;
      left: 0;
      margin-left: 60px;
      transform: rotate(250deg) scale(1, -1);
    }
  }

  /* Extend range of sizes for column layout on just this one */
  @media screen and (max-width: 800px) {
    height: 350px;
    
    .top-values__body { flex-direction: column; }
    .top-values__value-column { width: 100%; }
  }
}

// Formerly chart-3, chart-4
.metric--starting-challenges,
.metric--growing-challenges {
  width: 500px;
  height: 300px;
  margin-left: 50px;
  margin-right: 50px;

  .top-values__title {
    margin-bottom: 20px;
  }

  .top-values__title-decoration--1 {
    height: 60px;
    width: 60px;
    margin-top: -10px;
    margin-left: 100px;
    top: 0;
    left: 0;
  } 
  
  .top-values__title-decoration--2 {
    height: 50px;
    width: 50px;
    margin-top: 80px;
    margin-right: 10px;
  }

  .top-values__value-column {
    width: 95%;
  }

  @media screen and (max-width: 700px) {
    .top-values__title-text {
      font-size: 16px;
    }

    .top-values__value-text-outer {
      font-size: 12px;
    }

    .top-values__character--1 {
      width: 250px;
      height: 250px;
      top: 0;
    }
  }
}

// Formerly chart-3
.metric--starting-challenges {
  top: 0;
  margin-top: 50px;

  .top-values__character--1 {
    left: 0;
    background: url('~/assets/sk-jeEedhJXoR0.png')
      no-repeat center / contain;
    margin-top: -190px;
    margin-left: -80px;
  }

  @media screen and (max-width: 700px) {
    margin-top: 130px;
    margin-left: 0px;
    margin-right: 0px;

    .top-values__character--1 {
      left: 0;
      margin-top: -180px;
      margin-left: -30px;
    }
  }
}

// Formerly chart-4
.metric--growing-challenges {
  margin-top: 100px;

  .top-values__character--1 {
    right: 0;
    background: url('~/assets/good-faces-lY8ZoxeGUhU.png')
      no-repeat center / contain;
    margin-top: -230px;
    margin-right: -70px;
  }

  @media screen and (max-width: 700px) {
    margin-top: 80px;
    margin-left: 0px;
    margin-right: 0px;
    height: 260px;

    .top-values__character--1 {
      right: 0;
      margin-top: -170px;
      margin-right: -20px;
    }
  }
}

// Formerly chart-5
.metric--adverse-experiences {
  height: 330px;

  .top-values__character--1 {
    right: 0;
    width: 400px;
    height: 400px;
    background: url('~/assets/engin-akyurt.png')
      no-repeat center / contain;
    margin-top: -200px;
    margin-right: -130px;
  }

  .top-values__character--2 {
    width: 400px;
    height: 400px;
    background: url('~/assets/engin-akyurt.png')
      no-repeat center / contain;
    margin-top: 60px;
    margin-left: -100px;
    transform: rotate(200deg);
  }

  .top-values__body-decoration--1 {
    position: absolute;
    background-color: #F7E733;
    z-index: -10;
    height: 50px;
    width: 190px;
    bottom: 0;
    left: 0;
    margin-bottom: 50px;
    margin-left: 150px;
    transform: rotate(-15deg);
  }

  .top-values__body-decoration--2 {
    position: absolute;
    background-color: #F7E733;
    z-index: -10;
    height: 70px;
    width: 190px;
    bottom: 0;
    right: 0;
    margin-bottom: 130px;
    margin-right: 80px;
    transform: rotate(25deg);
  }

  .top-values__value-column {
    margin-left: 15px;
    margin-right: 15px;
  }

  .top-values__value-text-outer {
    position: absolute;
    left: 0;
    top: 0;
    width: 80%;
    margin-left: 80px;
    margin-top: -10px;
  }

  .top-values__quotemark--opening,
  .top-values__quotemark--closing {
    display: none;
  }

  .top-values__value {
    margin: 15px 0;
  }

  .top-values__value-box {
    width: unset;
    height: 40px;
    font-size: 30px;
    line-height: 1;
    padding: 5px 20px 5px 20px;
  }

  .top-values__value-text-inner {
    margin: 5px 10px;
  }

  .top-values__value--1,
  .top-values__value--6 { 
    .top-values__value-box { background: #CD157D; }
  }
  .top-values__value--2,
  .top-values__value--8 {
    .top-values__value-box { background: #F68C28; }
  }
  .top-values__value--3,
  .top-values__value--7 {
    .top-values__value-box { background: #55ACEE; }
  }
  .top-values__value--4,
  .top-values__value--5 {
    .top-values__value-box { background: #4676D3; }
  }

  .top-values__value-arrow {
    display: none;
  }

  @media screen and (max-width: 600px) {
    height: 630px;
    margin-top: 50px;

    .top-values__character--1 {
      margin-top: -150px;
      margin-right: -20%;
    }

    .top-values__character--2 {
      margin-top: 290px;
      margin-left: -10%;
    }

    .top-values__body-decoration--1 {
      left: 0;
      margin-bottom: 130px;
      margin-left: 100px;
    }

    .top-values__body-decoration--2 {
      top: 0;
      right: 0;
      margin-top: 130px;
    }
  }
}
