$stupidly-small: 350px;
$small-max-breakpoint: 500px;
$small-screen-breakpoint: 700px; /* same as main nav */
$column-reflow-breakpoint: 768px; /* same as bootstrap column transition */
$medium-max-breakpoint: 992px; /* same as bootstrap column transition */
$text-wrap-transition-4-3: 1050px; /* text goes from 4 lines to 3 */
$text-wrap-transition-3-2: 1360px; /* text goes from 3 lines to 2 - yes there is a better way to do this, no I'm not going to do it today */

.findings-intro__row {
  justify-content: center;

  @media screen and (max-width: $medium-max-breakpoint) {
    justify-content: right;
  }

  @media screen and (max-width: $small-screen-breakpoint) {
    justify-content: center;
  }
}

.findings-intro__outer {
  position: relative;
  padding: 40px 60px;
  background-color: white;
  margin-top: 50px;
  margin-bottom: 72px;
  
  @media screen and (max-width: $text-wrap-transition-3-2) {
    margin-bottom: 36px;
  }
  
  @media screen and (max-width: $text-wrap-transition-4-3) {
    padding: 40px 30px;
  }

  @media screen and (max-width: $medium-max-breakpoint) {
    margin-bottom: 0;
    min-height: 200px;
  }

  @media screen and (max-width: $column-reflow-breakpoint) {
    margin-top: 120px;
  }

  @media screen and (max-width: $small-screen-breakpoint) {
    padding: 30px;
    margin-top: 65px;
    min-height: unset;
  }

  @media screen and (max-width: $small-max-breakpoint) {
    margin-top: 52px;
  }
}

.findings-intro__heading {
  position: absolute;
  font-size: 45px;
  font-family: DIN-Alternate-Bold;
  line-height: 90px;
  top: -58px;
  left: 35px;

  @media screen and (max-width: $small-max-breakpoint) {
    font-size: 32px;
    top: -50px;
    left: 22px;
  }

  @media screen and (max-width: $stupidly-small) {
    font-size: 24px;
  }
}

.findings-intro__heading-text {
  position: relative;
  z-index: 2;
}

.findings-intro__heading-highlight {
  position: absolute;
  left: 100px;
  width: 9.6em;
  height: 24px;
  transform: translateX(-27%) translateY(195%) rotate(-1.07deg);
  background: #F7E733;
  z-index: 1;

  @media screen and (max-width: $small-max-breakpoint) {
    width: 110%;
    left: 25%;
    height: 20px;
    top: 8px;
  }
}

.findings-intro__body {
  font-size: 24px;
  padding: 0;
  margin: 0;

  @media screen and (max-width: $small-max-breakpoint) {
    font-size: 20px;
  }
}

.findings-intro__arrow {
  position: absolute;
  width: 160px;
  top: 25px;
  right: -50px;
  z-index: 1;
  transform: scaleX(-1) rotate(33deg);
  pointer-events: none;
  
  @media screen and (max-width: $text-wrap-transition-4-3) {
    right: -75px;
  }

  @media screen and (max-width: $medium-max-breakpoint) {
    width: 135px;
    top: 74px;
    left: -115px;
    transform: rotate(50deg);
    z-index: 3;

    /** Other versions in case the team doesn't like the above */
    /*
    top: 20px;
    left: -100px;
    transform: rotate(43deg);
    */

    /*
    top: 47px;
    left: -115px;
    transform: rotate(50deg);
    */
  }

  @media screen and (max-width: $column-reflow-breakpoint) {
    display: none;
  }
}
